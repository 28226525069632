import React, { useState } from "react"

import { Frame } from "../../layout"
import { H1, Detail, Link } from "../../elements"
import {
  InterviewList,
  Breadcrumb,
  Pagination,
  Header,
} from "../../compositions"
import { SEO } from "../../helpers"
import { graphql } from "gatsby"
import {
  InterviewListQuery,
  GraphCms_Interview,
  GraphCms_Page,
} from "../../../../graphql-types"

export const query = graphql`
  query InterviewList(
    $skip: Int!
    $limit: Int!
    $regex: String!
    $pathname: String!
  ) {
    graphCmsPage(url: { pathname: { eq: $pathname } }) {
      ...PageFields
    }
    allGraphCmsInterview(
      filter: { page: { url: { pathname: { regex: $regex } } } }
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...InterviewFields
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`

type Props = {
  data: InterviewListQuery
  pageContext: any
}

const Interviews = ({ data }: Props) => {
  const page = data.graphCmsPage as GraphCms_Page

  const defaultPageInfo = data.allGraphCmsInterview.pageInfo
  const defaultArticles = data.allGraphCmsInterview.nodes

  const [articles, setArticles] = useState(defaultArticles)
  const [pageInfo, setPageInfo] = useState(defaultPageInfo)

  if (defaultPageInfo.currentPage > 1) {
    page.url!.pathname = `/roportajlar/sayfa/${pageInfo.currentPage}/`
  }

  const onLoadMore = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    try {
      const response = await fetch(
        `/page-data/roportajlar/sayfa/${
          pageInfo.currentPage + 1
        }/page-data.json`
      ).then(response => response.json())

      const nextPage = response.result.data.allGraphCmsInterview.nodes

      setArticles(articles.concat(nextPage))
      setPageInfo(response.result.data.allGraphCmsInterview.pageInfo)
    } catch (error) {}
  }

  return (
    <Frame>
      <SEO page={page}>
        {defaultPageInfo.hasPreviousPage && (
          <link
            rel="prev"
            href={
              defaultPageInfo.currentPage == 2
                ? `https://cizzgi.com/roportajlar/`
                : `https://cizzgi.com/roportajlar/sayfa/${
                    defaultPageInfo.currentPage - 1
                  }/`
            }
          />
        )}

        {defaultPageInfo.hasNextPage && (
          <link
            rel="next"
            href={`https://cizzgi.com/roportajlar/sayfa/${
              defaultPageInfo.currentPage + 1
            }/`}
          />
        )}
      </SEO>

      <Header className="z-10" />
      <div className="bg-gradient">
        <div className="relative max-w-screen-lg mx-auto py-3 px-4">
          <Breadcrumb breadcrumb={page.breadcrumb} />
        </div>
      </div>

      <div className="relative z-0 max-w-screen-lg mx-auto px-3 sm:px-4 py-16">
        <section className="pt-5 mb-14">
          <H1 className="text-5xl AvenirLTProBlackOblique mb-3">
            Yurt Dışında Yaşam Röportajları
          </H1>

          <Detail className="text-lg text-gray-dark max-w-screen-sm">
            Yurt dışına gidenler neler yaparak orada iş buldular, ne gibi
            sorunlarla karşılaşıp o sorunları nasıl çözdüler. Sizler için
            derlediğimiz röportaj serileri.
          </Detail>

          <InterviewList
            interviews={articles as GraphCms_Interview[]}
            className="mt-10"
          />
        </section>

        <React.Fragment>
          {pageInfo.hasNextPage && (
            <div className="mt-16 text-center">
              <Link
                to={`/roportajlar/sayfa/${pageInfo.currentPage + 1}/`}
                onClick={onLoadMore}
                type="secondary"
                className="text-sm inline-block"
              >
                Daha fazla göster
              </Link>
            </div>
          )}
        </React.Fragment>
      </div>
    </Frame>
  )
}

export default Interviews
